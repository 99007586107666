@import '~ngx-toastr/toastr';
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
$base-color: #AD141E;
@font-face {
  font-family: 'Noto Sans Kufi Arabic'   ;
    font-style: normal;
    font-weight: 400;
    src: url(/assets/fonts/custom/ArbFONTS-NotoKufiArabic-Regular.ttf);
  }
  @font-face {
    font-family: 'Noto Sans Kufi Arabic';
    font-style: normal;
    font-weight: 700;
    src: url(/assets/fonts/custom/ArbFONTS-NotoKufiArabic-Bold.ttf);
  }
 
html:lang(ar){
      body,html,mat-form-field{
          font-family: 'Noto Sans Kufi Arabic', Courier, monospace !important;
          direction: rtl;
          text-align: right ;
          height: 100%;
          margin: 0; 
      }

  // body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
 
      .text-default {
        color:#4831d4;
      }
     
      .btn-default {
        background-color :#4831d4;
        color:#fff ;
        border: 1px solid #4831d4; 
        &:hover {
          transition: background-color 0.5s ease-in-out;
          background-color:#fff;
          color:#4831d4;
          border: 1px solid #4831d4;

          .notification {
            transition: background-color 0.5s ease-in-out;
            background-color :#4831d4;
            color:#fff ;
          }
        }
      }
  
      .btn-outline-default {
        &:hover {
          transition: background-color 0.5s ease-in-out;
          background-color:#4831d4;
          color:#fff;
          border: 1px solid #4831d4;

          .notification {
            transition: background-color 0.5s ease-in-out;
            background-color :#fff;
            color:#4831d4;
          }
        }
      }

      .btn-outline-danger , .btn-outline-success , .btn-outline-warning{
        transition: background-color 0.5s ease-in-out;
        &:hover {
          .mat-icon {
            color:#fff !important;
          }
        }
      }
 
      .btn-outline-default{
        background-color: #fff;
        border: 1px solid #4831d4;
        color:#4831d4;
      }
 
      .card {
        .card-header {
          border-bottom: 1px solid transparent;
          &:hover {
            color:#4831d4;
          }
        }
         .card-body {
          padding: 40px ;
         }
      }
 
      form{
        mat-form-field{
          width: 100%;
          height: 50px !important;
          .mat-form-field-wrapper{
            padding-bottom: 5px !important;
          }
          .password-icons,.show-hide{
            position: absolute;
            left: 5px;
            top: 10px;
            span{
              margin: 3px;
              color: #2b2b2b;
            }
          }
        }
        // in login component
        .show-hide{
          right: auto;
          left: 20px ;
          & > span.show:before{
            content: "عرض";
          }
          & > span:before{
            content: "إخفاء";
          }
        }
        div.errors-container{
          p.error-element{
            font-size: 75%;
            color: #f44336;
            &::before{
              font-family: "Font Awesome 5 Free"; font-weight: 600; content: "\f06a";
              margin-left: 3px;
            }
          }
        }
      }
  
      .mat-menu-item {
        &::-webkit-scrollbar {
          width:2px !important ;
        }
      
        &::-webkit-scrollbar-track {
            background:transparent;
        }
      
        &::-webkit-scrollbar-thumb {
            background:transparent;
            border-radius: 30px;
        }
      
        &::-webkit-scrollbar-thumb:hover {
            background:transparent;
        }
      }
      
      .table-responsive , .example-table-container {  
        overflow-x: scroll !important;
        &::-webkit-scrollbar {
          height:4px !important ;
        }
      
        &::-webkit-scrollbar-track {
            background: #eceaea;
        }
      
        &::-webkit-scrollbar-thumb {
            background:#cacaca;
            border-radius: 30px;
        }
      
        &::-webkit-scrollbar-thumb:hover {
            background:#cacaca;
        }

        table {
          th {
            min-width: 150px;
            background-color: #f4f3fc;
            border-color: #f4f3fc;
            color:#4831d4;
          
          }
          th, td {
            height:50px;
          }
        }
  /////////////////////mat-table////////////////////
        .mat-table{
          margin: auto;
        }

        tr.mat-header-row { 
         // background-color: #343A3F;
          // border-color: #454d55;

          background-color: #f4f3fc;
          border-color: #f4f3fc;
        
         th {
          // color: #fff;
          color:#4831d4;
          text-align: center;
          font-size: 14px;
          font-family: 'Noto Sans Kufi Arabic';
          }
        }

        td.mat-cell {
          text-align: center;
        }
      }

      .form-group {
        input {
          height: 50px;
          border: 1px solid #ccc;
          &::placeholder {
            color:#ddd ;
            font-size: 13px;
          }
          &:focus , &:hover {
            box-shadow: none ;
            border: 1px solid #4831d4;
            outline:none ; 
          }
        }
      }

      .eye {
        position: relative;
        float: left !important;
        bottom: 35px;
        left: 15px !important;
        .mat-icon {
          color:#4831d4;
          vertical-align: middle;
          margin-top: 0px;
        }
        .mat-icon.visibility-off {
          color:black;
        }
      }
 
      .dropzone{
        &.is-invalid{
          // border-color: red ;
          border-color:red ;
          margin-bottom: 20px;
        }
      }
    
      .demo-toggles {
        display: flex;
        flex-direction: column;
      }

      .demo-toggles * {
        margin-bottom: 16px;
      }

      .demo-options {
        display: flex;
        flex-direction: column;
        width: 600px;
      }

      .demo-data * {
        margin: 16px 0;
      }

      .demo-paginator {
        width: 600px;
      }

      .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links {
        overflow-y: auto;
        overflow-x: hidden;
      }

      .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .logo-wrapper, .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .logo-icon-wrapper {
        padding: 31px;
        background-color: #fff;
      }

      .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .according-menu i{
        transform: rotate(180deg);
      }

      .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links > li:first-child {
        display: block !important;
      }

      .rtl .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .according-menu {
        right: unset;
        top: 15px !important;
        // left: 0px !important; 
      }

      .rtl .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .sidebar-wrapper .sidebar-main .sidebar-links > li a svg {
        margin-right: unset;
        margin-left: 0px !important; 
      }
   
  //////////////////////////mat-option/////////////////////////
      .mat-option.abnormal {
        display: none;
      }

      .mat-option.normal {
        background-color: transparent;
      }
      
  //////////////////////////mat-form-field/////////////////////////
      .mat-form-field-appearance-legacy .mat-form-field-infix {
        border: 1px solid #d6d6d6;
        border-radius: 5px; 
        &:active , &:hover {
          border: 1px solid #4831d4;
        }
      }
      
      .mat-form-field-appearance-legacy .mat-form-field-underline {
        height: 0px !important;
      }
    
      
      .mat-form-field-appearance-legacy .mat-form-field-label {
        margin-top: 10px !important;
        margin-right: 10px !important;
      }
  ////////////////////mat-select/////////////////////////
      .mat-select-value {
        height: 30px;
        text-indent:10px;
        padding-top: 10px;
      }

      .mat-select {
        height: 40px; 
      }

      .mat-select-arrow-wrapper {
      padding-top: 10px;
      padding-left: 10px;
      }
  //////////////////////////mat-icon/////////////////////////
      a , button , .mat-icon {
        cursor: pointer;
      }

      .mat-icon {
        font-size: 22px;
        margin: 2px auto 0px auto;
      }
    /////////////////////////modal scroll/////////////////////
      .cdk-global-overlay-wrapper {
        overflow-y:auto !important;
      }
    
    /////////////////////mat-slide-toggle//////////////////////
      :focus {
          outline-color: transparent;
      }

      .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
        background-color:#5746c8;
      }

      .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
        background-color: #f4f3fc;
      }

      .mat-slide-toggle .mat-slide-toggle-ripple {
        display: none;
      }
      
      .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
        right:22px;
      }

      .mat-menu-item:hover:not([disabled]){
        background-color: transparent !important;
      }
    /////////////////////mat-menu//////////////////////
      .mat-elevation-z4 {
        box-shadow: 0px 2px 4px -1px rgb(233 233 233 / 20%), 0px 4px 5px 0px rgb(233 233 233 / 20%), 0px 1px 10px 0px rgba(233 233 233 / 20%);
      }

      .mat-menu-item { 
        line-height: 38px !important; 
        min-height:100px !important;
      }

      .mat-menu-item .mat-menu-ripple {
        visibility: hidden !important;
      }

    /////////////////////custom buttons//////////////////////
    .text-pink {
      color:#ea2087;
    }
    
    .btn-pink {
      border-radius: 5px;
      background-color: rgba(234, 32, 135, 0.15);
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color:#ea2087;
      &:hover {
        background-color: rgba(234, 32, 135, 0.15);
        color:#ea2087;
      }
      
    }

    .btn-outline-pink {
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      border-radius: 5px;
      background-color: #fff;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color:#ea2087;
      border:1px solid #ea2087;
      // height: 43px;
      &:hover {
        background-color: rgba(234, 32, 135, 0.15);
        border:1px solid rgba(234, 32, 135, 0.15);
        color:#ea2087;
      }
    }
    
    .badge-pink {
      color:#fff;
      background-color:#ea2087 ;
      margin: auto;
      text-align: center;
    }
 
    .text-purple {
      color:#ae18b8;
    }
    
    .btn-purple {
      border-radius: 5px;
      background-color:#ae18b83d;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color:#ae18b8;
      &:hover {
        background-color: #ae18b83d;
        color:#ae18b8;
      }
    
    }

    .btn-outline-purple {
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      border-radius: 5px;
      background-color: #fff;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color:#ae18b8;
      border:1px solid #ae18b8;
      // height: 43px;
    &:hover {
      background-color: #ae18b83d;
      border: 1px solid #ae18b83d;
      color: #ae18b8;
    }
    }

    //////////////notifications dropdown///////////////
      .left-header .level-menu .header-level-menu {
        width: 250px;
      }
      
    ////////////////////mat-expansion ///////////////////
      .mat-expansion-panel:not([class*=mat-elevation-z]) {
        // box-shadow: 0px 3px 1px -2px rgb(187 186 186 / 12%), 0px 2px 2px 0px rgb(187 186 186 / 12%), 0px 1px 5px 0px rgb(187 186 186 / 12%) !important;
        box-shadow: none !important;
      }
      
  ////////////////////control buttons///////////////////
      img.delete {
        filter: brightness(0) saturate(100%) invert(45%) sepia(43%) saturate(3968%) hue-rotate(303deg) brightness(96%) contrast(89%);
      }

      img.edit {
        filter: brightness(0) saturate(100%) invert(11%) sepia(94%) saturate(6330%) hue-rotate(254deg) brightness(89%) contrast(85%);
      }
 
  //////////////////////mat-radio//////////////////////
      .mat-radio-label-content {
        font-family: 'Noto Sans Kufi Arabic';
      }

      .mat-radio-button {
        border-radius: 5px;
        display: block !important;
      }
  /////////////////////////toast/////////////////////////
      .toast-success{
        background-color:#5546b3 !important;
        border-radius: 8px;
      }


      .form-control[readonly] {
        background-color: #f5f7f9 !important;
        opacity: 1;
        border: 1px solid transparent  !important; 
        &:hover , &:focus {
          border : 1px solid transparent !important;
        }
    }
}
  
   
 
   .fa-angle-right.icon { 
     &:before {
        content: '' !important;
        display: none !important;
     } 
  } 
 
  .mat-form-field-appearance-legacy .mat-form-field-ripple {
    height: 0px !important;
   }
 
  .show-hide { 
    top:60px !important;
  }